// extracted by mini-css-extract-plugin
export var about_banner = "about-module--about_banner--rQ2MU";
export var about_banner_content_inner = "about-module--about_banner_content_inner--GzGcg";
export var about_banner_content_inner_wrapper = "about-module--about_banner_content_inner_wrapper--QU1qI";
export var about_banner_content_wrapper = "about-module--about_banner_content_wrapper--7IwFN";
export var about_content_section2_inner = "about-module--about_content_section2_inner--a0yWB";
export var about_content_section2_inner_wrapper = "about-module--about_content_section2_inner_wrapper--g2IIo";
export var about_content_section2_wrapper = "about-module--about_content_section2_wrapper--rBwxS";
export var about_crew_wrapper = "about-module--about_crew_wrapper--7dxhK";
export var about_image_text = "about-module--about_image_text---Qji2";
export var about_image_wrapper = "about-module--about_image_wrapper--d1GfA";
export var about_img1 = "about-module--about_img1--Q-K7q";
export var about_img2 = "about-module--about_img2--cXFeq";
export var about_img3 = "about-module--about_img3--v8KsQ";
export var about_offer_content = "about-module--about_offer_content--wNLHC";
export var about_offer_content2 = "about-module--about_offer_content2--c1D5M";
export var about_offer_content_inner = "about-module--about_offer_content_inner--09BOl";
export var about_offer_content_inner2 = "about-module--about_offer_content_inner2--jZXAf";
export var about_offer_wrapper = "about-module--about_offer_wrapper--5MFji";
export var about_quote_inner = "about-module--about_quote_inner--7McxR";
export var about_quote_wrapper = "about-module--about_quote_wrapper--izEPU";
export var about_section_button = "about-module--about_section_button--tJrzC";
export var about_section_content_text = "about-module--about_section_content_text--HWQ7U";
export var about_section_flex1 = "about-module--about_section_flex1--dK7IA";
export var about_section_inner = "about-module--about_section_inner--rY0X2";
export var about_section_wrapper = "about-module--about_section_wrapper--syMUD";
export var about_subfooter_content = "about-module--about_subfooter_content--lcLwN";
export var about_subfooter_wrapper = "about-module--about_subfooter_wrapper--5yqGv";
export var about_wrapper = "about-module--about_wrapper--KqBx7";
export var bannerImg_overLay = "about-module--bannerImg_overLay--SqTmQ";
export var bannerImg_wrapper = "about-module--bannerImg_wrapper--9O0wu";
export var bottomflex_inner = "about-module--bottomflex_inner--ARqHE";
export var bottomflex_wrapper = "about-module--bottomflex_wrapper--t0UEo";
export var crew_member_content = "about-module--crew_member_content--H4RZ2";
export var crew_member_contents = "about-module--crew_member_contents--SJ2OF";
export var crew_member_email = "about-module--crew_member_email--acE2J";
export var crew_member_name = "about-module--crew_member_name--jPHA3";
export var crew_member_position = "about-module--crew_member_position--JS7+0";
export var crew_member_wrapper = "about-module--crew_member_wrapper--7k+0H";
export var earth = "about-module--earth--Tm1lb";
export var inner_carousel = "about-module--inner_carousel--vP9RP";
export var inner_flex = "about-module--inner_flex--oX7mA";
export var testimonial_card_wrapper = "about-module--testimonial_card_wrapper--pPyT-";
export var testimonial_carousel = "about-module--testimonial_carousel--fG2iV";
export var testimonial_header = "about-module--testimonial_header--rSp8G";
export var testimonial_inner = "about-module--testimonial_inner--4RMbb";
export var testimonial_inner_card = "about-module--testimonial_inner_card---uzIf";
export var testimonial_inner_content = "about-module--testimonial_inner_content--D0Pjc";
export var testimonial_text = "about-module--testimonial_text--U+HNp";
export var testimonial_user_companyName = "about-module--testimonial_user_companyName--BP9Hn";
export var testimonial_user_name = "about-module--testimonial_user_name--gnRSk";
export var testimonial_user_profile = "about-module--testimonial_user_profile--pxWSY";
export var testimonial_wrapper = "about-module--testimonial_wrapper--VArHE";